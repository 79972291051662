<template>
  <v-app>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h3>Quick Setup your website</h3>
          <h5>{{this.website.title}}</h5>
        </div>
      </div>

      <div class="white-bg wrap-container">
        <v-stepper v-model="e1" vertical v-if="!$vuetify.breakpoint.xsOnly">
          <v-row>
            <v-col cols="12" md="4" lg="4" sm="12">
              <v-stepper-step :complete="e1 > 1" step="1">Use of Website</v-stepper-step>

              <v-stepper-step :complete="e1 > 2" step="2">Setup your Branding</v-stepper-step>

              <v-stepper-step :complete="e1 > 3" step="3">Tell us about your website</v-stepper-step>

              <v-stepper-step :complete="e1 > 4" step="4">Select a template</v-stepper-step>

              <v-stepper-step :complete="e1 > 5" step="5">Import Sample data</v-stepper-step>

              <v-stepper-step :complete="e1 > 6" step="6">Website Default Settings</v-stepper-step>
              <v-stepper-step :complete="e1 > 7" step="7">Currency</v-stepper-step>

              <v-stepper-step :complete="e1 > 8" step="8">Complete & Launch Dashboard</v-stepper-step>
            </v-col>
            <v-col cols="12" md="8" lg="8" sm="12">
              <v-stepper-content step="1">
                <div class="subtitle-1">
                  Do you want to transform your website into an
                  <strong>Online Store</strong> ?
                </div>
                <v-radio-group v-model="sellProduct.sell_product_online">
                  <v-radio value="1">
                    <template v-slot:label>
                      <div>
                        <strong class="success--text">Yes, I am building an online store</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="0">
                    <template v-slot:label>
                      <div>
                        <strong class="primary--text">No, I just want a simple informative website</strong>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <!-- <span>
                  Ecommerce :
                  {{ sellProduct.sell_product_online }}
                </span>-->
                <div class="pull-right">
                  <v-btn color="primary" :loading="loading" @click.prevent="step1()">Next</v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="2">
                <div class="subtitle-1">
                  Please Upload Your website
                  <strong>Logo</strong> and
                  <strong>Favicons</strong>
                </div>
                <v-row>
                  <v-col>
                    <v-file-input
                      :rules="rules"
                      accept="image/png, image/jpeg, image/bmp"
                      hint
                      persistent-hint
                      prepend-inner-icon="mdi-camera"
                      label="Logo"
                      v-model="media.site_logo"
                      @change="changeLogo"
                      outlined
                    ></v-file-input>
                  </v-col>
                  <v-col>
                    <v-file-input
                      :rules="rules"
                      accept="image/png, image/jpeg, image/bmp"
                      hint
                      persistent-hint
                      prepend-inner-icon="mdi-camera"
                      label="Favicon"
                      v-model="media.site_favicon"
                      @change="changeFavIcon"
                      outlined
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div id="logoPreview">
                      <img v-if="logoUrl" :src="logoUrl" />
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div id="favIconPreview">
                      <img v-if="favIconUrl" :src="favIconUrl" />
                    </div>
                  </v-col>
                </v-row>

                <div class="pull-right">
                  <!-- <v-btn @click.prevent="e1 = 1" @click="e1 = 1">Previous</v-btn> -->
                  <v-btn color="primary" :loading="loading" @click.prevent="step2">Continue</v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="3">
                <p class>Which category best describes this website ?</p>
                <v-row>
                  <v-col class="d-flex" cols="6" sm="6">
                    <v-select
                      :items="categories"
                      v-model="category"
                      @change=" changeSubCatergory(`${category.id}`)"
                      item-text="title"
                      item-value="id"
                      return-object
                      label="Category"
                      outlined
                    ></v-select>
                    <br />
                  </v-col>
                  <v-col class="d-flex" cols="6" sm="6">
                    <v-select
                      :items="subcatergories"
                      v-model="newCategory.sub_category_id"
                      item-text="title"
                      item-value="id"
                      label="Sub Category"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col v-if="categroyRequiredError">
                    <small class="text-danger">Please select a category</small>
                  </v-col>
                </v-row>

                <div class="pull-right">
                  <!-- <v-btn @click="e1 = 2">Previous</v-btn> -->
                  <v-btn color="primary" :loading="loading" @click.prevent="step3">Continue</v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="4">
                <div class="subtitle-1">
                  Here are some of the templates that we
                  <strong>recommend</strong> for your business
                </div>

                <v-row>
                  <v-col class="d-flex" cols="12" sm="12">
                    <!-- <div v-if="templates ? templates.length>0 :0">
                      <v-radio-group v-model="template" row>
                        <v-radio :value="template" v-for="(template,i) in templates" :key="i">
                          <template v-slot:label>
                            <v-card outlined class="mx-auto" max-width="200">
                              <v-card-title>{{template.title}}</v-card-title>
                            </v-card>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>-->
                    <div>
                      <v-radio-group v-model="template" row>
                        <v-radio
                          :value="template"
                          v-for="(template,i) in defaultstemplates"
                          :key="i"
                        >
                          <template v-slot:label>
                            <v-card outlined class="mx-auto" max-width="200">
                              <progressive-img :src="template.thumbnail_thumb_image"></progressive-img>
                              <v-card-title>{{template.title}}</v-card-title>
                            </v-card>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                </v-row>
                <v-col v-if="templateRequiredError">
                  <small class="text-danger">Please select a template</small>
                </v-col>
                <div class="pull-right">
                  <v-btn @click.prevent="e1 = 3; setup_steps= 3">Previous</v-btn>
                  <v-btn color="primary" :loading="loading" @click.prevent="step4">Continue</v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="5">
                <div class="subtitle-1">
                  Available Pages to
                  <strong>Import</strong>
                </div>
                <v-row>
                  <v-col class="d-flex" cols="2" sm="2" v-for="(page, index) in pages" :key="index">
                    <v-checkbox v-model="selectedPages" multiple :label="page" :value="page"></v-checkbox>
                  </v-col>
                  <!-- <pre>
                        {{ importPage }}
                  </pre>-->
                </v-row>

                <div class="pull-right">
                  <!-- <v-btn @click.prevent="e1 = 4">Previous</v-btn> -->
                  <v-btn color="primary" :loading="loading" @click.prevent="step5">Continue</v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="6">
                <div class="subtitle-1">
                  Set Your Defaults
                  <strong>Pages</strong> and
                  <strong>Settings</strong>
                </div>

                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-select
                      :items="websitePages"
                      v-model="website_setting.home_page_id"
                      label="Home Page"
                      item-text="title"
                      item-value="id"
                      persistent-hint
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-select
                      :items="websitePages"
                      v-model="website_setting.blog_page_id"
                      label="Blog Page"
                      item-text="title"
                      item-value="id"
                      persistent-hint
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      label="Website Title"
                      hint="Eg. Yelko"
                      persistent-hint
                      v-model="website_setting.site_title"
                      :error-messages="titleErrors"
                      @blur="$v.website_setting.site_title.$touch()"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      label="Website Tagline"
                      hint="Eg.: Powering Business, Informations & Websites"
                      v-model="website_setting.site_tagline"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      label="Contact Email"
                      v-model="website_setting.site_email"
                      :error-messages="emailErrors"
                      @blur="$v.website_setting.site_email.$touch()"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      label="Contact Phone"
                      v-model="website_setting.site_phone"
                      :error-messages="phoneErrors"
                      @blur="$v.website_setting.site_phone.$touch()"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </v-col>

                  <!-- <pre>
                        {{ website_setting }}
                  </pre>-->
                </v-row>
                <div class="pull-right">
                  <!-- <v-btn @click.prevent="e1 = 6">Previous</v-btn> -->
                  <v-btn color="primary" :loading="loading" @click.prevent="step6">Continue</v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="7">
                <currency-setting
                  :page_setting="website_setting"
                  :loading="loading"
                  @update_currency="currencySettingUpdate"
                ></currency-setting>
              </v-stepper-content>

              <v-stepper-content step="8">
                <v-row>
                  <div class="col-xl-12 col-md-12">
                    <v-card flat>
                      <div class="d-flex flex-no-wrap justify-center">
                        <div>
                          <v-card-subtitle>
                            <progressive-img
                              height="320"
                              all
                              position="center"
                              contain
                              src="@/assets/media/general/quick-setup-website.gif"
                            ></progressive-img>
                            <!-- <v-img height="500" all position="center"  contain src="assets/media/illustrations/undraw_confirmed_81ex.svg"></v-img> -->
                          </v-card-subtitle>
                          <div v-if="e1 >= 8">
                            <h3 class="text-center mt-5">All Done !</h3>
                            <v-card-actions class="justify-center">
                              <v-btn color="primary" @click="finish">Finish & Launch Dashboard</v-btn>
                            </v-card-actions>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </v-row>
              </v-stepper-content>
            </v-col>
          </v-row>
        </v-stepper>
        <v-stepper v-model="e1" vertical v-else>
          <!-- <v-col cols="12" md="4" lg="4" sm="12"> -->
          <v-stepper-step :complete="e1 > 1" step="1" class="custom_mobile_label">Use of Website</v-stepper-step>
          <v-stepper-content step="1">
            <div class="subtitle-1">
              Do you want to transform your website into an
              <strong>Online Store</strong> ?
            </div>
            <v-radio-group v-model="sellProduct.sell_product_online">
              <v-radio value="1">
                <template v-slot:label>
                  <div>
                    <strong class="success--text">Yes, I am building an online store</strong>
                  </div>
                </template>
              </v-radio>
              <v-radio value="0">
                <template v-slot:label>
                  <div>
                    <strong class="primary--text">No, I just want a simple informative website</strong>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            <!-- <span>
                  Ecommerce :
                  {{ sellProduct.sell_product_online }}
            </span>-->
            <div class="pull-right">
              <v-btn color="primary" :loading="loading" @click.prevent="step1()">Next</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step
            class="custom_mobile_label"
            :complete="e1 > 2"
            step="2"
          >Setup your Branding</v-stepper-step>
          <v-stepper-content step="2">
            <div class="subtitle-1">
              Please Upload Your website
              <strong>Logo</strong> and
              <strong>Favicons</strong>
            </div>
            <v-row>
              <v-col>
                <v-file-input
                  :rules="rules"
                  accept="image/png, image/jpeg, image/bmp"
                  hint
                  persistent-hint
                  prepend-inner-icon="mdi-camera"
                  label="Logo"
                  v-model="media.site_logo"
                  @change="changeLogo"
                  outlined
                ></v-file-input>
              </v-col>
              <v-col>
                <v-file-input
                  :rules="rules"
                  accept="image/png, image/jpeg, image/bmp"
                  hint
                  persistent-hint
                  prepend-inner-icon="mdi-camera"
                  label="Favicon"
                  v-model="media.site_favicon"
                  @change="changeFavIcon"
                  outlined
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div id="logoPreview">
                  <img v-if="logoUrl" :src="logoUrl" />
                </div>
              </v-col>
              <v-col cols="6">
                <div id="favIconPreview">
                  <img v-if="favIconUrl" :src="favIconUrl" />
                </div>
              </v-col>
            </v-row>

            <div class="pull-right">
              <!-- <v-btn @click.prevent="e1 = 1" @click="e1 = 1">Previous</v-btn> -->
              <v-btn color="primary" :loading="loading" @click.prevent="step2">Continue</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step
            class="custom_mobile_label"
            :complete="e1 > 3"
            step="3"
          >Tell us about your website</v-stepper-step>
          <v-stepper-content step="3">
            <p class>Which category best describes this website ?</p>
            <v-row>
              <v-col class="d-flex" cols="6" sm="6">
                <v-select
                  :items="categories"
                  v-model="category"
                  @change=" changeSubCatergory(`${category.id}`)"
                  item-text="title"
                  item-value="id"
                  return-object
                  label="Category"
                  outlined
                ></v-select>
                <br />
              </v-col>
              <v-col class="d-flex" cols="6" sm="6">
                <v-select
                  :items="subcatergories"
                  v-model="newCategory.sub_category_id"
                  item-text="title"
                  item-value="id"
                  label="Sub Category"
                  outlined
                ></v-select>
              </v-col>
              <v-col v-if="categroyRequiredError">
                <small class="text-danger">Please select a category</small>
              </v-col>
            </v-row>

            <div class="pull-right">
              <!-- <v-btn @click="e1 = 2">Previous</v-btn> -->
              <v-btn color="primary" :loading="loading" @click.prevent="step3">Continue</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step :complete="e1 > 4" step="4">Select a template</v-stepper-step>
          <v-stepper-content step="4">
            <div class="subtitle-1">
              Here are some of the templates that we
              <strong>recommend</strong> for your business
            </div>

            <v-row>
              <v-col class="d-flex" cols="12" sm="12">
                <v-radio-group v-model="template" row>
                  <v-radio :value="template" v-for="(template,i) in templates" :key="i">
                    <template v-slot:label>
                      <v-card outlined class="mx-auto" max-width="200">
                        <!-- <v-img :src="template.thumbnail_thumb_image"></v-img> -->
                        <v-card-title>{{template.title}}</v-card-title>
                      </v-card>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-col v-if="templateRequiredError">
              <small class="text-danger">Please select a template</small>
            </v-col>
            <div class="pull-right">
              <!-- <v-btn @click.prevent="e1 = 3">Previous</v-btn> -->
              <v-btn color="primary" :loading="loading" @click.prevent="step4">Continue</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step :complete="e1 > 5" step="5">Import Sample data</v-stepper-step>
          <v-stepper-content step="5">
            <div class="subtitle-1">
              Available Pages to
              <strong>Import</strong>
            </div>
            <v-row>
              <v-col class="d-flex" cols="2" sm="2" v-for="(page, index) in pages" :key="index">
                <v-checkbox v-model="selectedPages" multiple :label="page" :value="page"></v-checkbox>
              </v-col>
              <!-- <pre>
                        {{ importPage }}
              </pre>-->
            </v-row>

            <div class="pull-right">
              <!-- <v-btn @click.prevent="e1 = 4">Previous</v-btn> -->
              <v-btn color="primary" :loading="loading" @click.prevent="step5">Continue</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step :complete="e1 > 6" step="6">Website Default Settings</v-stepper-step>
          <v-stepper-content step="6">
            <div class="subtitle-1">
              Set Your Defaults
              <strong>Pages</strong> and
              <strong>Settings</strong>
            </div>

            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  :items="websitePages"
                  v-model="website_setting.home_page_id"
                  label="Home Page"
                  item-text="title"
                  item-value="id"
                  persistent-hint
                  outlined
                ></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  :items="websitePages"
                  v-model="website_setting.blog_page_id"
                  label="Blog Page"
                  item-text="title"
                  item-value="id"
                  persistent-hint
                  outlined
                ></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Website Title"
                  hint="Eg. Yelko"
                  persistent-hint
                  v-model="website_setting.site_title"
                  :error-messages="titleErrors"
                  @blur="$v.website_setting.site_title.$touch()"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Website Tagline"
                  hint="Eg.: Powering Business, Informations & Websites"
                  v-model="website_setting.site_tagline"
                  persistent-hint
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Contact Email"
                  v-model="website_setting.site_email"
                  :error-messages="emailErrors"
                  @blur="$v.website_setting.site_email.$touch()"
                  persistent-hint
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Contact Phone"
                  v-model="website_setting.site_phone"
                  :error-messages="phoneErrors"
                  @blur="$v.website_setting.site_phone.$touch()"
                  persistent-hint
                  outlined
                ></v-text-field>
              </v-col>

              <!-- <pre>
                        {{ website_setting }}
              </pre>-->
            </v-row>
            <div class="pull-right">
              <!-- <v-btn @click.prevent="e1 = 6">Previous</v-btn> -->
              <v-btn color="primary" :loading="loading" @click.prevent="step6">Continue</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step :complete="e1 > 7" step="7">Currency Setting</v-stepper-step>
          <!-- </v-col> -->

          <v-stepper-content step="7">
            <currency-setting
              :page_setting="website_setting"
              :loading="loading"
              @update_currency="currencySettingUpdate"
            ></currency-setting>
          </v-stepper-content>

          <v-stepper-step :complete="e1 > 8" step="8">Complete & Launch Dashboard</v-stepper-step>
          <!-- </v-col> -->

          <v-stepper-content step="8">
            <v-row>
              <div class="col-xl-12 col-md-12">
                <v-card flat>
                  <div class="d-flex flex-no-wrap justify-center">
                    <div>
                      <v-card-subtitle>
                        <progressive-img
                          height="320"
                          all
                          position="center"
                          contain
                          src="@/assets/media/general/quick-setup-website.gif"
                        ></progressive-img>
                        <!-- <v-img height="500" all position="center"  contain src="assets/media/illustrations/undraw_confirmed_81ex.svg"></v-img> -->
                      </v-card-subtitle>
                      <div v-if="e1 >= 8 ">
                        <h3 class="text-center mt-5">All Done !</h3>
                        <v-card-actions class="justify-center">
                          <v-btn color="primary" @click="finish">Finish & Launch Dashboard</v-btn>
                        </v-card-actions>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-row>
          </v-stepper-content>
        </v-stepper>
      </div>
    </div>
  </v-app>
</template>
<script>
import OgranisationCategoryService from "@/services/organisation/OrgnisationCategoryService";
import Website from "@/services/Websites/WebsiteService";
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
import WebsiteMarketplaceProduct from "@/services/Websites/WebsiteMarketPlaceProductService";
import WebsiteMarketplaceCategory from "@/services/Websites/WebsiteMarketPlaceCategoryService";
import Websitetemplate from "@/services/Websites/WebsiteTemplateService";
import WebsitePage from "@/services/Websites/WebsitePageService";
import WebsiteMenu from "@/services/Websites/WebsiteMenuService";
import WebsiteMenuItem from "@/services/Websites/WebsiteMenuItemService";
import { isNullablePhoneNumner, isMobileNumner } from "@/common/validation";
import { validationMixin } from "vuelidate";
import EcommerceOrderStatusService from "@/services/store/EcommerceOrderStatusService";
import CurrencySetting from "./store-setting/CurrencySetting";

const ogranisationCategory = new OgranisationCategoryService();
const WebsiteService = new Website();
const WebsiteSettingService = new WebsiteSetting();
const WebsiteMarketPlaceProductService = new WebsiteMarketplaceProduct();
const WebsiteMarketPlaceCategoryService = new WebsiteMarketplaceCategory();
const WebsiteTemplateService = new Websitetemplate();
const WebsitePageService = new WebsitePage();
const WebsiteMenuService = new WebsiteMenu();
const WebsiteMenuItemService = new WebsiteMenuItem();
const orderStatusService = new EcommerceOrderStatusService();

import {
  email,
  required,
  minLength,
  sameAs,
  url
} from "vuelidate/lib/validators";
export default {
  name: "website-quick-setup",
  mixins: [validationMixin],
  validations: {
    website_setting: {
      site_title: { required },
      site_phone: { isNullablePhoneNumner },
      site_email: { required, email }
    }
  },
  components: {
    CurrencySetting
  },

  data() {
    return {
      setting: {},
      domain_name: null,
      e1: 1,
      rules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "Logo size should be less than 2 MB!"
      ],
      loading: false,
      categroyRequiredError: false,
      templateRequiredError: false,
      setup_steps: 1,
      newCategory: {
        title: "",
        category_id: null,
        sub_category_id: null
      },
      sellProduct: {
        sell_product_online: 0,
        setup_steps: 1,
        header_design_id: 1,
        footer_design_id: 1
      },
      settings: {
        setup_steps: 0
      },
      settingId: null,
      category: {},
      subcatergories: [],
      isEommerce: false,
      logoUrl: null,
      favIconUrl: null,
      media: {
        site_logo: null,
        site_favicon: null
      },
      templates: [],
      defaultstemplates: [],
      template: {
        category_id: null,
        demo_url: null,
        desc: null,
        demo_url: null,
        folder: null,
        id: null,
        is_active: null,
        is_ecommerce: null,
        thumbnail: null,
        thumbnail_image: null,
        thumbnail_thumb_image: null,
        title: null
      },
      menuItem: {
        parent_id: null,
        page_id: null,
        blog_id: null,
        blog_category_id: null,
        shop_category_id: null,
        product_id: null,
        title: null,
        link: null,
        position: null,
        depth: null,
        type: "link",
        is_active: 1
      },
      menuItemData: [],
      radios: null,

      website_setting: {
        title: null,
        home_page: null,
        blog_page: null,
        site_title: null,
        site_tagline: null,
        site_phone: null,
        site_email: null,
        is_active: 1,
        setup_steps: 6
      },
      website: {
        id: null,
        title: null,
        user_id: null,
        template_id: null,
        is_custom_domain: null,
        plan_id: null,
        plan_expiry_date: null,
        lifetime_membership: null,
        is_linked_organisation: null,
        is_active: null,
        linked_organisation_id: null
      },
      categories: [],
      pages: [""],
      websitePages: [],
      selectedPages: [],
      importPage: {
        pages: null
      }
    };
  },
  methods: {
    changeLogo(e) {
      this.media.site_logo = e;
      this.logoUrl = URL.createObjectURL(e);
    },
    changeFavIcon(e) {
      this.media.site_favicon = e;
      this.favIconUrl = URL.createObjectURL(e);
    },
    changeSubCatergory(cat) {
      this.newCategory.title = this.category.title;
      this.newCategory.category_id = cat;
      WebsiteMarketPlaceCategoryService.getSubCategory(cat)
        .then(res => {
          this.subcatergories = res.data.data;
        })
        .catch(err => {});
    },
    buildMenuItemData() {
      this.selectedPages.filter((value, index) => {
        this.menuItemData.push({
          page_id: null,
          title: value,
          link: null,
          position: null,
          depth: null,
          type: "link",
          is_active: 1
        });
      });
    },
    step6() {
      this.loading = true;
      this.$v.website_setting.$touch();
      if (this.setting.sell_product_online == 1)
        this.website_setting.setup_steps = 6;
      else {
        this.website_setting.setup_steps = 7;
      }
      if (!this.$v.website_setting.$error) {
        WebsiteSettingService.update(
          this.siteUrl,
          this.settingId,
          this.website_setting
        )
          .then(res => {
            this.loading = false;
            this.$snotify.success("Setting Updated Successfully");
            if (this.setting.sell_product_online == 1) this.e1 = 7;
            else {
              this.e1 = 8;
            }
          })
          .catch(err => {
            // console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 3000);
      }
    },
    step5() {
      this.loading = true;
      this.importPage.pages = this.selectedPages;
      this.buildMenuItemData();
      WebsiteTemplateService.importPage(
        this.domain_name,
        this.website.template_id,
        this.importPage
      )
        .then(res => {
          this.settings.setup_steps = this.setup_steps;

          WebsiteSettingService.update(
            this.domain_name,
            this.settingId,
            this.settings
          ).then(res => {
            this.loading = false;
            this.e1 = 6;
            this.setup_steps = res.data.setting.setup_steps + 1;
            this.getWebsitePages();
            this.$snotify.success("Settings  Updated");
          });
        })
        .catch(err => {});
    },
    step4() {
      this.loading = true;
      if (this.template.id != null) {
        this.website.template_id = this.template.id;
        WebsiteService.update(this.siteUrl, this.website).then(res => {
          WebsiteTemplateService.getPageByTemplate(this.template.id)
            .then(res => {
              this.pages = res.data;
              this.settings.setup_steps = this.setup_steps;
              // this.settings.setup_steps = this.setup_steps;
              WebsiteSettingService.update(
                this.siteUrl,
                this.settingId,
                this.settings
              ).then(res => {
                this.loading = false;
                this.e1 = 5;
                this.setup_steps = res.data.setting.setup_steps + 1;
                this.$snotify.success("Settings  Updated");
              });
            })
            .catch(err => {});
        });
      } else {
        this.templateRequiredError = true;
      }
    },
    step3() {
      this.loading = true;
      if (this.newCategory.category_id) {
        WebsiteMarketPlaceCategoryService.fetchTemplate(
          this.newCategory.category_id,
          this.newCategory.sub_category_id
        )
          .then(res => {
            this.templates = res.data;

            let data = {
              category_id: this.newCategory.category_id,
              sub_category_id: this.newCategory.sub_category_id,
              setup_steps: this.setup_steps
            };
            WebsiteSettingService.update(
              this.siteUrl,
              this.settingId,
              data
            ).then(res => {
              this.loading = false;
              this.e1 = 4;
              this.setup_steps = res.data.setting.setup_steps + 1;
              this.$snotify.success("Settings  Updated");
            });
          })
          .catch(err => {});
      } else {
        this.categroyRequiredError = true;
      }
    },
    step2() {
      this.loading = true;
      let fd = new FormData();
      if (this.media.site_logo != null) {
        fd.append("site_logo", this.media.site_logo, this.media.site_logo.name);
      }
      if (this.media.site_favicon != null) {
        fd.append(
          "site_favicon",
          this.media.site_favicon,
          this.media.site_favicon.name
        );
      }

      fd.append("setup_steps", this.setup_steps);

      WebsiteSettingService.update(this.siteUrl, this.settingId, fd).then(
        res => {
          this.loading = false;
          this.e1 = 3;
          this.setup_steps = res.data.setting.setup_steps + 1;
          this.$snotify.success("Settings  Updated");
        }
      );
      //call Api to upload
    },
    step1() {
      this.loading = true;
      WebsiteSettingService.create(this.siteUrl, this.sellProduct)
        .then(res => {
          this.loading = false;
          this.e1 = 2;
          this.setup_steps = res.data.setting.setup_steps + 1;
          this.settingId = res.data.setting.id;
          this.$snotify.success("Setting Updated Successfully");
          if (res.data.setting.sell_product_online == 1)
            this.createEcommerceStatus();
        })
        .catch(err => {});
    },
    updateWebsiteSetting() {
      this.$v.website_setting.$touch();
      if (!this.$v.website_setting.$error) {
        WebsiteSettingService.create(this.website.title, this.website_setting)
          .then(res => {
            this.$snotify.success("Setting Updated Successfully");
          })
          .catch(err => {});
      }
    },
    currencySettingUpdate(data) {
      data.setup_steps = 7;
      WebsiteSettingService.update(this.siteUrl, this.settingId, data)
        .then(res => {
          this.$snotify.success("Setting Updated Successfully");
          this.e1 = 8;
        })
        .catch(err => {});
    },
    getPages() {
      WebsiteTemplateService.getPageByTemplate(this.website.template_id)
        .then(res => {
          this.pages = res.data;
        })
        .catch(err => {});
    },
    getTemplate() {
      WebsiteMarketPlaceCategoryService.fetchTemplate(
        this.newCategory.category_id,
        this.newCategory.sub_category_id
      )
        .then(res => {
          this.templates = res.data;
        })
        .catch(err => {});
    },
    getCategory() {
      WebsiteMarketPlaceCategoryService.paginate()
        .then(res => {
          this.categories = res.data.data;
        })
        .catch(err => {});
    },
    getMarketPlaceTemplate() {
      WebsiteMarketPlaceProductService.paginate()
        .then(res => {
          this.defaultstemplates = res.data.data;
        })
        .catch(err => {});
    },
    getSettings() {
      WebsiteSettingService.paginate(this.siteUrl).then(res => {
        if (res.data) {
          this.setting = res.data;
          this.setup_steps = res.data.setup_steps + 1;
          this.settingId = res.data.id;
          this.e1 = this.setup_steps;
          if (res.data.category_id && res.data.sub_category_id) {
            this.newCategory.category_id = res.data.category_id;
            this.newCategory.sub_category_id = res.data.sub_category_id;
            this.getTemplate();
          }
        }
      });
    },
    initWebsite() {
      WebsiteService.show(this.siteUrl).then(res => {
        this.website = res.data.website;
        this.website_setting.title = this.website.domain_name;
        this.website.is_custom_domain = this.website.is_custom_domain;
        if (this.website.is_custom_domain == 1) {
          this.domain_name = this.website.domain_name;
        } else {
          this.domain_name = this.website.sub_domain_name;
        }
        if (this.website.template_id) {
          this.getPages();
        }
      });
    },
    getWebsitePages(type = "default", flag = null) {
      WebsitePageService.paginate(this.siteUrl, type, flag).then(res => {
        this.websitePages = res.data.pages;
      });
    },
    createEcommerceStatus() {
      orderStatusService
        .generate(this.siteUrl)
        .then(response => {})
        .catch(error => {});
    },
    finish() {
      this.loading = true;
      WebsiteService.finish(this.siteUrl)
        .then(response => {
          this.$router.push({
            name: "manage-websites",
            params: { domainname: this.siteUrl }
          });
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.initWebsite();
    this.getCategory();
    this.getMarketPlaceTemplate();
    if (this.siteUrl != undefined) {
      this.getSettings();
      this.getWebsitePages();
    }
    this.website_setting.site_email = this.currentUser.email;
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.website_setting.site_title.$dirty) return errors;
      !this.$v.website_setting.site_title.required &&
        errors.push("Title is required ");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.website_setting.site_email.$dirty) return errors;
      !this.$v.website_setting.site_email.required &&
        errors.push("E-mail is required");
      !this.$v.website_setting.site_email.email &&
        errors.push("Must be valid e-mail");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.website_setting.site_phone.$dirty) return errors;
      !this.$v.website_setting.site_phone.isNullablePhoneNumner &&
        errors.push("Phone must be valid");
      return errors;
    },
    siteUrl() {
      return this.$route.params.domainname;
    }
  }
};
</script>


<style scoped>
#logoPreview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logoPreview img {
  max-width: 100%;
  max-height: 250px;
}
#favIconPreview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#favIconPreview img {
  max-width: 100%;
  max-height: 250px;
}

.v-stepper__step {
  margin: 10px !important;
  font-size: 15px !important;
}
.v-stepper__step--active {
  background: #efefef !important;
  /* padding: 15px !important; */
  border-radius: 10px !important;
  color: white !important;
  font-size: 15px !important;
  font-weight: bold !important;
  position: relative;
  z-index: 1;
}
.stpper-optional-text {
  font-size: 14px !important;
  margin-top: 5px;
}
.v-stepper__step.v-stepper__step--active:after {
  content: "";
  position: absolute;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 30px solid #efefef;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
}
.v-stepper__step .v-stepper__step--inactive {
  font-size: 15px !important;
  font-weight: bold !important;
}
.v-stepper__step--active .v-stepper__label {
  /* text-shadow: 0px 0px 0px black; */
  color: white !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin-left: 0;
}

.white-bg {
  background-color: #fff;
}
.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
  display: block !important;
}
@media only screen and (max-width: 767px) {
  .custom_mobile_label .v-stepper__label {
    display: block !important;
  }
}
.v-stepper__label {
  display: none !important;
}
</style>
